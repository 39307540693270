.sidebar {
    position: fixed;
    z-index: 9999;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.25);
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;

    &__menu {
        width: 389px;
        height: 100%;
        box-shadow: -15px 2px 30px 0 rgba(0, 0, 0, 0.6);
        background-color: #1c316a;
        position: absolute;
        right: -389px;
        top: 0;
        transition: all 0.5s;   
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -ms-transition: all 0.5s;

        @include media-breakpoint-down(lg){
            width: 320px;
            right: -320px;
            overflow: auto;
        }

        .content-table {
            display: table;
            width: 100%;
            height: 100%;

            .content-row {
                display: table-row;

                .content-cell {
                    display: table-cell;
                    vertical-align: middle;
                }
            }
        }

        &-close {
            position: absolute;
            right: 50px;
            top: 38px;
            cursor: pointer;
        }

        #sidebar__logo {
            width: 144px;
            margin-right: 0px;
            margin-bottom: 40px;
            margin-left: 111px;
            margin-top: 20px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            .submenu {
                margin-left: 121px;
                margin-top: 14px;

                a {
                    font-size: 14px;
                    color: #ffffff;
                }
            }

            li {
                list-style-type: none;
                padding-left: 0px;
                position: relative;
                margin-bottom: 17px;

                .menu-mark {
                    display: inline-block;
                    height: 1px;
                    background: #FF9D00;
                    width: 80px;
                    margin-right: 20px;
                    opacity: 0;
                    transition: all 0.5s;
                    -webkit-transition: all 0.5s;
                    -moz-transition: all 0.5s;
                    -o-transition: all 0.5s;
                    -ms-transition: all 0.5s;
                    vertical-align: middle;
                }

                &:hover {

                    .menu-mark {
                        opacity: 1;
                    }
                }
         
                a {
                    font-size: 15px;
                    color: #ffffff;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                        color: #ff6277;
                    }   
                }
            }
        }
    }
    &__menu::-webkit-scrollbar {
        display: none;
      }
}

.active {
    opacity: 1;
    pointer-events: all;

    .sidebar__menu {
        right: 0;
    }
}

