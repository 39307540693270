.alert {
    top: 80px;
    z-index: 2;
    text-align: center;
}
.home-slider{
    height: 700px;

    @include media-breakpoint-down(xs){
        height: 100vh;
    }

    .hero {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 10;
        

        &__community{
            position: absolute;
            display: flex;
            font-family: 'La Belle Aurore', cursive;
            font-size: 50px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.9;
            letter-spacing: -0.5px;
            color: white;
            top: 40%;
            width: 100%;

            @include media-breakpoint-down(md){
                justify-content: center;
            }

            p {
                width: 45%;
                margin-left: 90px;

                @include media-breakpoint-down(lg){
                    width: 70%;
                }

                @include media-breakpoint-down(md){
                    margin-left: 0px;
                    font-size: 50px;
                    text-align: center;
                }

                @include media-breakpoint-down(xs){
                    font-size: 30px;
                }
            }
        }

        &__presale {
            position: absolute;
            top: 65%;
            width: 100%;

            @include media-breakpoint-down(md){
                display: flex;
                justify-content: center;
            }

            img {
                width: 227px;
                bottom: 130px;
                margin-left: 90px;

                @include media-breakpoint-down(md){
                    margin-left: 0;
                }

                @include media-breakpoint-down(xs){
                    width: 157px;
                }
            }
        }

        &__slider-btns{
            position: absolute;
            top: 90%;
            width: 100%;

            @include media-breakpoint-down(xs){
                display: flex;
                justify-content: space-between;
            }

            .arrows{
                padding-left: 90px;

                @include media-breakpoint-down(xs){
                    display: flex;
                    padding-left: 25px;
                }

                img {
                    width: 65px;
                    height: 40px;
                }
            }

            .btn-brochure {
                display: flex;
                justify-content: center;

                button {
                    width: 180px;
                    height: 40px;
                    background-color: #ff6277;
                    font-size: 15px;
                    text-align: center;
                    border-radius: 25px;
                    font-weight: 900;
                    color: white;
                    border: none;
                    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.4);
                    -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.4);
                    -moz-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.4);
    
                    @include media-breakpoint-down(xs){
                        width: 120px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .carousel-inner {
        max-height: 100%;

        @include media-breakpoint-down(xs){
            height: 100%;
        }
    }

    #sl-1, #sl-2, #sl-3 {

        height: 730px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-down(xs){
            height: 100%;
        }
    }

    #sl-1 {
        background-image: url("/images/home/slider/slider-1.jpg");
    }

    #sl-2 {
        background-image: url("/images/home/slider/slider-2.jpg");
    }

    #sl-3 {
        background-image: url("/images/home/slider/slider-3.jpg");
    }

}