.privacy {

    padding-top: 150px;
    padding-bottom: 50px;

    h1 {
        font-family: 'La Belle Aurore', cursive;
        font-size: 60px;
        color: #008a41;
        margin-bottom: 60px;
    }

    &__subheader {
        font-weight: bold;
    }
}