.parque__slides {
    background-image: url('/images/residencial/bg-graybeige.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-bottom: 90px;

    &-modal {

        img {
            margin-top: -225px;
            cursor: pointer;

            @include media-breakpoint-down(md){
                margin-top: -140px;
            }

            @include media-breakpoint-down(xs){
                margin-top: 50px;
            }
        }
    }
}