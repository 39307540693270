.slick-home {
    z-index: 30;
    margin-top: -160px;
    overflow: hidden;
    
    @include media-breakpoint-down(lg){
        margin-top: auto;
    }

    img {
        opacity: 0.6;
        transition: all 300ms ease;

        @include media-breakpoint-down(md){
            opacity: 1;
        }
    }
    
    .slick-track {
        padding-top: 50px;
        padding-bottom: 50px;

        @include media-breakpoint-down(md){
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &__buttons {
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
        margin-left: 100px;

        @include media-breakpoint-down(lg){
            margin-bottom: 50px;
            margin-left: auto;
            justify-content: center;
        }

        img {
            width: 65px;
            height: 45px;
            cursor: pointer;
            z-index: 30;
        }
    }

    .slick-center {

        img {
            -moz-transform: scale(1.20);
            -ms-transform: scale(1.20);
            -o-transform: scale(1.20);
            -webkit-transform: scale(1.20);
            opacity: 1;
            transform: scale(1.20);

            @include media-breakpoint-down(md) {
                -moz-transform: scale(1.00);
                -ms-transform: scale(1.00);
                -o-transform: scale(1.00);
                -webkit-transform: scale(1.00);
                transform: scale(1.00);
            }
        }
    }

        /* the slides */
  .slick-slide {
    margin: 0 50px;
  }
  /* the parent */
  .slick-list {
    margin: 0 -50px;
  }
}