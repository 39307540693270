.news {
    background-image: url('/images/home/bg-gray.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-top: 80px;
    padding-bottom: 80px;

    @include media-breakpoint-down(xs){
        text-align: center;
    }


    h1 {
        font-family: 'La Belle Aurore', cursive;
        font-size: 60px;
        color: #008a41;
        margin-bottom: 50px;

        @include media-breakpoint-down(xs){
            font-size: 50px;
        }
    }

    h2 {
        color: #1c316a;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px;
    }


    p {
        color: $parragraphs;
        font-size: 15px; 
    }

    &__photo {
        position: relative;
        
        img {
            margin-bottom: 20px;
        }
    
        span {
            position: absolute;
            left: 30px;
            bottom: 40px;
            text-align: center;
            color: #ffffff;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 7px;
            font-size: 14px;
            height: 30px;
            width: 160px;
            border-radius: 19px;
            background-color: #008a41;
        }
    }

    &__text {
        min-height: 140px;
    }

    &__show {
        width: 115px;
        height: 40px;
        border-radius: 25px;
        border: solid 2px #008a41;
        font-size: 15px;
        font-weight: bold;
        color: #008a41;
        background: none;

        @include media-breakpoint-down(xs){
            margin-bottom: 40px;
        }
    }

    &__divisor{
        display: flex;
        justify-content: center;
        margin-top: 50px;
        width: 100%;

        button {
            width: 181px;
            height: 40px;
            border-radius: 25px;
            background-color: #008a41;
            border: none;
            font-size: 15px;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
            margin: 0 30px;

            @include media-breakpoint-down(lg){
                margin: 0 5px;
            }
        }
    }

}