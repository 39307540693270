.masterplan {
    &__plane {
        min-height: 1000px;

        .stage-figure {
            cursor: pointer;
        }
    }

    &__button {
        margin-top: 30px;
        width: 160px;
        height: 40px;
        border-radius: 25px;
        box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.4);
        background-color: #1c316a;
        border: none;
        font-size: 15px;
        font-weight: 900;
        line-height: 1.47;
        text-align: center;
        color: #ffffff;
    }
}
.card-info {
    text-align: center;
    h1 {
        font-family: "La Belle Aurore", cursive;
        font-size: 40px;
        color: white;

        @include media-breakpoint-down(xs) {
            font-size: 30px;
        }
    }
    &__residencial,
    &__comercial,
    &__park {
        &__info {
            position: relative;
            top: 50%;
        }
        display: flex;
        align-items: center;
        background-repeat: no-repeat;
        flex-direction: column;
        height: 400px;
        width: 400px;
        background-position: center;
        background-image: url("/images/masterplan/MasterPlan_residencial.png");
        background-size: 90% 90%;
    }
    &__comercial {
        background-image: url("/images/masterplan/MasterPlan_comercial.png");
        &__info {
            top: 40%;
        }
    }
    &__park {
        background-image: url("/images/masterplan/MasterPlan_parque.png");
    }
    .mb-title {
        margin-bottom: 20px;
    }
}
.masterplan__info {
    background-image: url("/images/residencial/bg-beige-res.png");
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-top: 90px;
    height: 540px;

    @include media-breakpoint-down(xs) {
        padding-top: 60px;
        height: auto;
    }

    p {
        color: #666666;
        font-size: 15px;
    }

    &-text {
        padding-left: 50px;
        width: 85%;

        @include media-breakpoint-down(md) {
            padding-left: 0;
            width: 100%;
            text-align: center;
        }
    }

    &-ref {
        p {
            font-size: 15px;
            color: #666666;

            @include media-breakpoint-down(lg) {
                text-align: center;
            }
        }

        &-icons {
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            @include media-breakpoint-down(lg) {
                justify-content: center;
            }

            p {
                font-size: 16px;
                margin-bottom: 0;
                margin-top: 0;
                padding-left: 40px;
                font-weight: bold;
            }
        }
    }
}
.masterplan__intro {
    background-image: url("/images/parque/bg-blue.png");
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-top: 130px;
    height: 245px;

    &-title {
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            font-family: "La Belle Aurore", cursive;
            font-size: 60px;
            color: white;

            @include media-breakpoint-down(xs) {
                font-size: 55px;
            }
        }
    }
}
.masterplan__slides {
    background-image: url("/images/residencial/bg-graybeige.png");
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-bottom: 90px;

    &-modal {
        img {
            margin-top: -225px;
            cursor: pointer;

            @include media-breakpoint-down(md) {
                margin-top: -140px;
            }

            @include media-breakpoint-down(xs) {
                margin-top: 50px;
            }
        }
    }
}
.masterplan__quote {
    text-align: center;
    &-title {
        position: relative;
        font-family: "La Belle Aurore", cursive;
        color: green;
        font-size: 60px;
        @include media-breakpoint-down(xs) {
            font-size: 55px;
        }
    }
    &-iframe {
        border: 0;
    }
}
