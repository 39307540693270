.residencial__crisanto {

    &-info {
        color: $parragraphs;
        margin-left: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-down(xl){
            margin-left: auto;
            padding-left: 50px;
        }

        @include media-breakpoint-down(md){
            text-align: center;
            padding-left: 15px;
        }

        h1 {
            font-family: 'La Belle Aurore', cursive;
            font-size: 60px;
            color: #ff9d00;
        }

        p {
            font-size: 15px;
        }

        &-bg {
            font-weight: bold;
            font-style: italic;
            font-size: 17px;
        }

        .pink-slash {
            color: #ff6277;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

}