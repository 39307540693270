.comercial__info {
    background-image: url('/images/residencial/bg-beige-res.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-top: 90px;
    height: 540px;

    @include media-breakpoint-down(lg){
        height: auto;
    }

    @include media-breakpoint-down(xs){
        padding-top: 60px;
    }

    p {
        color: $parragraphs;
        font-size: 15px;
    }

    &-text {
        padding-left: 50px;
        width: 85%;

        @include media-breakpoint-down(lg){
            padding-left: 0;
            width: 100%;
            text-align: center;
        }
    }

    &-lotes {

        p {
            font-size: 15px;
            color: $parragraphs;

            @include media-breakpoint-down(lg){
                text-align: center;
            }
        }

        &-icons {
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            @include media-breakpoint-down(lg){
                justify-content: center;
            }

            p {
                font-size: 16px;
                margin-bottom: 0;
                margin-top: 0;
                padding-left: 40px;
                font-weight: bold;
            }            
        }
    }

}