.beach-house {
    background-image: url('/images/home/bg-beige.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    margin-top: -30px;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;

    @include media-breakpoint-down(md){
        height: auto;
    }

    &::before {
        content: '';
        background-image: url('/images/home/shape-introduccion.svg');
        position: absolute;
        height: 100%;
        width: 250px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        opacity: 0.5;

        @include media-breakpoint-down(lg){
            display: none;
        }
    }

    &__carousel {
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-down(md){
            justify-content: center;
        }

        .carousel-control-prev, .carousel-control-next {
            opacity: 1;
        }

        #carousel-beach {
            position: relative;
            width: 75%;

            .beach-img {
                cursor: pointer;
                width: 100%;
            }

            @include media-breakpoint-down(lg){
                width: 100%;
            }
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        @include media-breakpoint-down(md){
            text-align: center;
            margin-top: 50px;
        }

        &-title {
            position: relative;
            
            &::after {
                content: '';
                background-image: url('/images/home/vivara-sello.png');
                height: 100px;
                background-size: 100% 100%;
                width: 100px;
                top: -48px;
                right: -68px;
                position: absolute;

                @include media-breakpoint-down(md){
                    display: none;
                }

                @media (min-width: 992px) and (max-width: 1045px){
                    width: 67px;
                    top: -55px;
                    right: -44px;
                }
    
                @media (min-width: 1046px) and (max-width: 1260px){
                    width: 75px;
                    top: -55px;
                    right: -55px;
                }

            }

        }


        &::after {
            content: '';
            background-image: url('/images/home/shape-beach.png');
            background-repeat: no-repeat;
            position: absolute;
            background-size: 100% 100%;
            height: 400px;
            width: 400px;  
            right: 0;
            top: 85px;

            @include media-breakpoint-down(lg){
                top: 120px;
            }

            @include media-breakpoint-down(md){
                top: -65px;
            }

            @include media-breakpoint-down(xs){
                height: 395px;
                width: 397px;
                top: 0;
            }

        }

        h1 {
            font-family: 'La Belle Aurore', cursive;
            font-size: 60px;
            margin-bottom: 20px;
            color: #6fafd3;

            @include media-breakpoint-down(lg){
                font-size: 50px;
            }
        }
    
        p {
            font-size: 15px;
            color: $parragraphs;
            width: 90%;

            @include media-breakpoint-down(xs){
                width: 100%;
            }
        }

    }

}

#beach-modal {
    .modal-content {
        position: relative;
        display: block;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: transparent;
        background-clip: padding-box;
        border: none;
        border-radius: 0;
        outline: 0;
    }

    .modal-dialog {
        @include media-breakpoint-up(xs){
            max-width: 100vh;
        }
    }

    img {
        width: 100%;
    }
}