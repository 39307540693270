.residencial__ecovillas {
    margin-top: 50px;

    &-buttons {
        display: flex;
        justify-content: center;
        margin-top: 50px;

        img {
            width: 65px;
        }
    }

    h1 {
        font-family: 'La Belle Aurore', cursive;
        font-size: 60px;
        text-align: center;
        color: #ff6277;
        padding-top: 10px;

        @include media-breakpoint-down(xs){
            font-size: 45px;
        }
    }
}