header {
    width: 100%;
    height: 90px;
    background-image: url("/images/navbar/fondo-header.png");
    background-size: 100% 100%;
    position: fixed;
    z-index: 100;
    background-position: bottom;
    overflow: hidden;
    border: transparent;
        
    .shape{
        width: 370px;
        position: absolute;
        left: 25px;
        opacity: 0.40;

        @include media-breakpoint-down(xs){
            left: 0;
            width: 320px;
        }
    }

    .logo{
        margin-top: 24px;
        position: relative;
        z-index: 1;
        margin-left: 30px;

        @include media-breakpoint-down(xs){
            margin-left: 0;
            width: 100px;
        }
    }

    #vivara-logo {
        margin-left: 50px;

        @include media-breakpoint-down(md){
            margin-left: 20px;
        }
    }

    .menu{
        position: absolute;
        top: 40px;
        right: 83px;
        cursor: pointer;

        @include media-breakpoint-down(xs){
            top: 30px;
            right: 20px;
        }
    }

    .social-media {
        width: 40px;
        height: 105px;
        background-color: #008a41;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        padding-top: 15px;
        padding-left: 15px;

        img {
            margin-bottom: 15px;
            height: 15px;
            width: 15px;
        }

        #fb-icon {
            width: 12px;
        }
    }
}

.preloader,.loading {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('/images/home/Preloader.gif') 50% 50% no-repeat rgb(249,249,249);
}

.loading {
    position: relative;
    text-align: center;
    z-index: 1;
    width: 100%;
    height: 250px;
    background: url('/images/home/Preloader.gif') 50% 50% no-repeat transparent;
}