.comercial__artwalk{
    margin-left: 60px;

    @include media-breakpoint-down(md){
        margin-left: auto;
    }

    &-info {
        color: $parragraphs;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p, h1 {
            @include media-breakpoint-down(md){
                text-align: center;
            }
        }

        h1 {
            font-family: 'La Belle Aurore', cursive;
            font-size: 60px;
            color: #ff6277;
            margin-top: 0;
            margin-bottom: 35px;
            padding-top: 17px;
        }

        ul {
            list-style-type: disc;
            list-style-position: inside;
            padding-left: 0;
            column-count: 2;

            li {
                font-size: 14px;
                bottom: 3px;
                font-weight: bold;

                &::marker {
                    font-size: 25px;
                    font-weight: bold;
                    color: #ff6277;
                }
            }
        }
    }
}