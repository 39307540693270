.modal-contact {

    .modal-content {
        background-size: 100% 100%;
        -webkit-background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        background-color: transparent;
        background-image: url('/images/residencial/bg-contact.png');
        padding: 30px;
        border: none;

        .modal-header {
            padding: 0;
            border-bottom: none;
            border-top-left-radius: none;
            border-top-right-radius: none;

            button:focus {
                outline: none;
            }

            .close {
                padding: 0;
            }
        }

        h1 {
            font-family: 'La Belle Aurore', cursive;
            color: #008a41;
            font-size: 60px;
            text-align: center;
        }

        p {
            font-size: 13px;
            text-align: center;
            color: $parragraphs;
        }

        #thanks {
            font-weight: bold;
            color: #ff6277;
        }
    }
}