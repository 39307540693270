.comercial__park {

    &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        @include media-breakpoint-down(lg){
            text-align: center;
        }
        
        h1 {
            color: #6fafd3;
            font-family: 'La Belle Aurore', cursive;
            font-size: 60px;
            margin-bottom: 35px;
            padding-top: 17px;

        }

        p {
            font-size: 15px;
            color: $parragraphs;
        }
    }
}