.yucatan {
    background-image: url('/images/home/bg-pink.jpg');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-bottom: 100px;


    &__description{
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        @include media-breakpoint-down(xs){
            text-align: center;
            margin-top: 50px;
        }

        &::before {
            content: '';
            background-image: url('/images/home/shape-komunah-3.png');
            background-size: 100% 100%;
            height: 300px;
            width: 300px;
            position: absolute;
            left: -80px;
            bottom: 130px;

            @include media-breakpoint-down(lg){
                bottom: 0;
            }

            @include media-breakpoint-down(md){
                left: unset;
                right: 0;
                transform: rotateY(180deg);
            }

        }

        h2, h3{
            font-family: 'La Belle Aurore', cursive;
            color: #ff6277;
            line-height: 0.95;
            letter-spacing: -1.05px;
        }
        
        h2 {
            font-size: 60px;
            @include media-breakpoint-down(xs){
                font-size: 50px;
            }
        }
        
        h3 {
            font-size: 42px;

            @include media-breakpoint-down(xs){
                font-size: 32px;
                margin-top: 13px;
            }
        }

        p {
            color: $parragraphs;
            font-size: 15px;
        }
    }
}