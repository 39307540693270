// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');
@import '~@fortawesome/fontawesome-free/css/all.css';

@import '~venobox/venobox/venobox.min.css';
// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~animate.css/animate.min.css';

//SCSS Files
@import './navbar/header.scss';
@import './navbar/sidebar.scss';
@import './home/home.scss';
@import './home/komunah.scss';
@import './home/slick-home.scss';
@import './home/yucatan.scss';
@import './home/location.scss';
@import './home/mp-intro.scss';
@import './home/amenities.scss';
@import './home/beach-house.scss';
@import './home/slick-beach.scss';
@import './home/news.scss';
@import './home/contact.scss';
@import './footer/footer.scss';
@import './residencial/res_intro.scss';
@import './residencial/res_info.scss';
@import './residencial/res_summer.scss';
@import './residencial/slick-res.scss';
@import './residencial/res_crisanto.scss';
@import './residencial/res_villas.scss';
@import './residencial/modal.scss';
@import './area-comercial/com-intro.scss';
@import './area-comercial/com-info.scss';
@import './area-comercial/com-lotes.scss';
@import './area-comercial/com-art.scss';
@import './area-comercial/slick-com.scss';
@import './area-comercial/slick-park.scss';
@import './area-comercial/com-park.scss';
@import './parque-lineal/parque-intro.scss';
@import './parque-lineal/parque-info.scss';
@import './parque-lineal/parque-slides.scss';
@import './dashboard/auth.scss';
@import './privacy/privacy.scss';
@import './blog/blog.scss';
@import './blog-entry/entry-header.scss';
@import './blog-entry/entry-content.scss';
@import './lightbox/lightbox.scss';
@import './masterplan/masterplan.scss';
@import './faq/accordion.scss';
@import 'thankyou';

// Partials
@import './partials/quote_modal';

body {
    position: relative;
}
