.residencial__summer {
    background-image: url('/images/residencial/bg-graybeige.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-bottom: 90px;

    &-modal {

        img {
            margin-top: -225px;
            cursor: pointer;

            @include media-breakpoint-down(lg){
                margin-top: 0;
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-down(xs){
            text-align: center;
        }

        div:first-child {
            width: 90%;

            @include media-breakpoint-down(lg){
                text-align: center;
                margin-top: 30px;
                width: 100%;
            }
        }

        &-available {
            font-size: 17px;
            font-style: italic;
            color: $parragraphs;
            margin-top: 20px;
        }

        h1 {
            font-family: 'La Belle Aurore', cursive;
            font-size: 60px;
            color: #ff6277;

            @include media-breakpoint-down(xl){
                font-size: 50px;
            }
        }

        p {
            font-size: 15px;
            color: $parragraphs;
        }

    }
}

#orange-dot {
    width: 8px;
    height: 8px;
    background-color: #ff6277;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
}