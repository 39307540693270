/*************************
Accordion
*************************/
.faq {
    margin-top: 85px;
    h1 {
        font-family: 'La Belle Aurore', cursive;
        font-size: 60px;
        color: #6fafd3;
        @include media-breakpoint-down(xs){
            font-size: 55px;
        }
    }
    .bg-accordion {
        background-color: transparent;
        border-color: transparent;
    }
    .accordion .card .card-header {
        position: relative;
    }
    
    .accordion .card .card-header button:after {
        content: "\f067";
        position: absolute;
        top: 50%;
        left: 15px;
        font-weight: bold;
        color: $primary;
        transform: translateY(-50%);
        font: normal normal normal 14px/1 FontAwesome;
    }
    
    .accordion .card .card-header button.right:after {
        right: 15px;
        left: auto;
         color: #1c316a;
    }
    
    .accordion .card .card-header button.left:after {
        left: 0px;
        right: auto;
         color: #1c316a;
    }
    
    .accordion {
        .card {
            .card-header {
                button[aria-expanded=false] {
                     color: #1c316a;
                    &:after {
                        content: "+";
                        color: #1c316a;
                    }
                }
                
                button[aria-expanded=true] {
                    color: $primary;
                    &:after {
                        content: "-";
                        color: $primary;
                    }
                }
            }
        }
    }
    
}
