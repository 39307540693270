.komunah {
    background-image: url('/images/home/bg-beige.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-top: 130px;
    margin-top: -12px;
    padding-bottom: 304px;
    position: relative;
    z-index: 30;

    @include media-breakpoint-down(lg){
        padding-bottom: 100px;
    }

    &__intro{
        position: relative;
        background-image: url('/images/home/bg-intro.png');
        background-size: 100%;
        -webkit-background-size: 100%;
        -moz-background-size: 100%;
        width: 65%;
        font-family: 'La Belle Aurore', cursive;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #1c316a;
        padding: 35px;

        @include media-breakpoint-down(xs){
            font-size: 21px;
            width: 100%
        }

        &::before{
            content: '';
            background-image: url('/images/home/shape-introduccion.svg');
            background-repeat: no-repeat;
            position: absolute;
            height: 110px;
            width: 100px;
            left: 0;
            bottom: 0;
            margin-left: -22px;
            margin-bottom: -18px;

            @include media-breakpoint-down(xs){
                margin-left: -4px;
                margin-bottom: -40px;
            }
        }

        &::after{
            content: '';
            background-image: url('/images/home/shape-introduccion-2.svg');
            background-repeat: no-repeat;
            position: absolute;
            height: 98px;
            width: 162px;
            right: 0;
            bottom: 0;
            margin-right: -30px;
            margin-bottom: -10px;

            @include media-breakpoint-down(xs){
                margin-right: 0px;
                width: 100px;
            }
        }
    }

    &__divisor {

        @include media-breakpoint-down(xs){
            margin-bottom: 50px;
        }

        img {
            max-width: 100%;
            margin-top: 50px;
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-down(lg){
            margin-top: 50px;
        }

        @include media-breakpoint-down(xs){
            text-align: center;
        }

        p {
            font-size: 15px;
            line-height: 1.53;
            color: $parragraphs;
        }
    }
}