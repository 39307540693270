.blog-entry__content {
    margin-bottom: 50px;

    &-head {
        margin-top: -235px;

        @include media-breakpoint-down(lg){
            margin-top: -30px;
        }
    }

    &-social {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }

    &-post {
        text-align: center;

        
        h2 {
            font-size: 25px;
            color: #1c316a;
            margin: 30px 0px;
            font-weight: bold;
        }

        p {
            font-size: 14px;
            color: #666666;
        }

        img {
            margin-top: 30px;
            width: 100%;
            height: auto;
        }
    }

    &-contact {
        margin: 30px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-icons {

        span {
            font-size: 14px;
            font-weight: 500;
            color: #ff6277;
            padding-right: 10px;
        }

        i {
            color: rgb(102, 102, 102);
            height: 20px;
            width: 25px;
        }
    }

    &-navigation {
        
        #last-post {
            width: 65px;
            margin: 0 20px;

            @include media-breakpoint-down(lg){
                margin: auto;
            }
        }

        button {
            margin: 0 20px;

            @include media-breakpoint-down(lg){
                margin: auto;
            }
        }
    }
}