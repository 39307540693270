.location {
    background-image: url('/images/home/bg-location.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-top: 55px;
    margin-top: -30px;
    position: relative;

    &__description {

        
        div:first-child {            
            background-image: url('/images/home/bg-white.png');
            background-size: 100% 100%;
            padding-left: 60px;
            padding-right: 60px;
            position: relative;
            background-repeat: no-repeat;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            @include media-breakpoint-down(lg){
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 30px;
                padding-bottom: 30px;
                border-radius: 30px;
            }

            &::before {
                content: '';
                background-image: url('/images/home/shape-introduccion.svg');
                background-repeat: no-repeat;
                position: absolute;
                height: 110px;
                width: 100px;
                left: 0;
                bottom: 0;
                margin-left: -22px;

                @include media-breakpoint-down(xs){
                    margin-left: -10px;
                }
            }
        }

        h1 {
            font-family: 'La Belle Aurore', cursive;
            font-size: 50px;
            line-height: 0.95;
            letter-spacing: -1.05px;
            color: #ff9d00;
            margin-bottom: 30px;

        }

        p {
            color: $parragraphs;
            margin-bottom: 28px;
            font-size: 15px;    
            line-height: 1.53;    
        }

        a {
            color: #ff9d00 !important;
        }
    }


}