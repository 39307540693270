.parque__intro {
    background-image: url('/images/parque/bg-blue.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-top: 130px;
    height: 245px;

    &-title {
        display: flex;
        justify-content: center;
        align-items: center;
        
        h1 {
            font-family: 'La Belle Aurore', cursive;
            font-size: 60px;
            color: white;

            @include media-breakpoint-down(xs){
                font-size: 55px;
            }
        }
    }
}