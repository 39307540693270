.footer {
    background-image: url('/images/footer/bg-footer.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-top: 100px;
    margin-top: -30px;

    &__divisor{
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
    }

    #footer__logo {
        margin: 0 30px 0 30px;
    }

    &__info {

        margin-top: 100px;

        h3 {
            color: #ff9d00;
            font-size: 17px;
            font-weight: bold;  
        }

        ul {
            list-style: none;
            padding-left: 0;
            column-count: 2;

            a {
                color: white;
                text-decoration: none;
            }
        }

        &-marker {
            font-size: 13px;
            font-weight: 300;
            color: #ff6277;
        }

        &-contact {

            p {
                color: white;
            }
        }
    }

    &__disclaimer {
        text-align: center;
        font-size: 12px;
        color: white;
    }

    &__privacy {

        font-size: 12px;
        text-align: center;
        color: #ffffff;
        margin-top: 50px;
        padding-bottom: 50px;

        @include media-breakpoint-down(xs){
            margin-top: 0;
            padding-bottom: 15px;
        }

        span {
            font-weight: bold;
            color: #ff6277;
        }

        a {
            color: white;
        }
    }

}