.mp-intro {
    background-image: url('/images/home/master-fondo.jpg');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    height: 465px;
    display: flex;
    align-items: center;
    margin-top: -30px;

    @include media-breakpoint-down(xs){
        height: 460px;
    }

    &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h1 {
            font-family: 'La Belle Aurore', cursive;
            font-size: 60px;
            margin-bottom: 30px;
        }

        &__p {
            width: 60%;
            text-shadow: 0 3px 6px rgba(0, 0, 0, 0.45);

            @include media-breakpoint-down(xs){
                width: 100%;
            }
        }

        button {
            width: 160px;
            height: 40px;
            border-radius: 25px;
            box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.4);
            -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.4);
            -moz-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.4);
            background-color: #6fafd3;
            border: none;
            font-size: 15px;
            font-weight: 900;
            line-height: 1.47;
            text-align: center;
            color: #ffffff;
        }
    }
}