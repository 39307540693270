.parque__info {
    background-image: url('/images/residencial/bg-beige-res.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-top: 90px;
    height: 540px;

    @include media-breakpoint-down(xs){
        padding: 50px 0 50px 0;
        height: auto;
    }

    p {
        font-size: 15px;
        color: $parragraphs;
        margin-bottom: 0;
    }

}