.contact {
    background-image: url('/images/home/bg-contact.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-top: 80px;
    padding-bottom: 80px;

    @include media-breakpoint-down(xs){
        margin-top: -20px;
    }

    &__form {     
        background-image: url('/images/home/bg-white.png');
        background-size: 100% 100%;
        padding: 20px 60px 20px 60px;
        position: relative;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        @include media-breakpoint-down(xs){
            padding: 50px 15px 0px 15px;
            border-radius: 30px;
            background-color: white;
        }

        h1 {
            font-family: 'La Belle Aurore', cursive;
            font-size: 60px;
            color: #ff9d00;
            margin-bottom: 10px;
        }

        p {
            color: $parragraphs;
            font-size: 15px;
            margin-bottom: 15px;
        }

        &-inputs {
            text-align: left;
            color: #505349;
            z-index: 2;
            position: relative;
            background-color: white;

            label {
                font-style: italic; 
            }

            &-submit {
                color: #505349;
                font-size: 10px;

                @include media-breakpoint-down(md){
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 10px 0 10px 0;
                }

                a {
                    color: #505349  ;
                }

                input[type=checkbox]{
                    margin-top: 0;
                }

                input[type=submit]{
                    width: 100px;
                    height: 40px;
                    border-radius: 25px;
                    background-color: #6fafd3;
                    font-size: 15px;
                    font-weight: 900;
                    text-align: center;
                    color: #ffffff;
                    border: none;
                }
            }
        
            &-captcha {

                @include media-breakpoint-down(md){
                    display: flex;
                    justify-content: center;
                }

                .g-recaptcha {
    
                    transform: scale(0.64);
                    transform-origin: 0 0;

                    @include media-breakpoint-down(lg){
                        transform: scale(0.54);
                    }
    
                    @include media-breakpoint-down(md){
                        transform: none;
                    }
                }
            }

            .input-style{
                border: none;
                border-bottom: 2px dotted #FF6277;
                background: none;
                font-size: 14px;
                text-align: left;
            }
        }

        &-logo-vivara {
            padding-top: 15px;
            margin-bottom: 15px;
        }
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(md){
            
            img {
                margin-top: 30px;
            }
        }
    }
}
.optional {
    color: red;
    font-size: 12px;
}