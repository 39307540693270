.amenities {
    background-image: url('/images/home/bg-amenities.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    margin-top: -30px;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 70px;

    @include media-breakpoint-down(xs){
        height: auto;
    }

    &__box {
        border-bottom: 2px dotted #fff;
        min-height: 80px;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;

        @include media-breakpoint-down(md){
            margin-bottom: 10px;
        }
    }

    @include media-breakpoint-down(xs){
        margin-top: -10px;
    }

    h1 {
        font-family: 'La Belle Aurore', cursive;
        font-size: 60px;
        margin-bottom: 30px;
    }
}