.overlay {
  @media(max-width: 1174px) {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.158); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
}

.thank-you-bg {
  @media(min-width: 1175px) {
    background-image: url("/images/thankyou/thankyou-bg.jpg");
  }
  @media(max-width: 1174px) {
    background-image: url("/images/thankyou/thankyou-bg-mobile.jpg");
  }
  
  background-repeat: no-repeat;
  background-position-x: left;
  background-size: cover;
  height: 100vh;
}

.btn.btn-light.btn-brochure {
  min-width: 320px;
}

.btn-wrapper {
  @media(max-width: 674px) {
    margin-top: 600px;
  }
}

.btn.btn-primary.btn-whatsapp {
  background-color: #25D366;
  border-color: #000;
  min-width: 320px;
}

