.blog-entry__header {
    background-image: url('/images/residencial/bg-beige-res.png');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: top;
    padding-top: 90px;
    height: 378px;

    @include media-breakpoint-down(lg){
        height: auto;
    }

    @include media-breakpoint-down(xs){
        padding-top: 60px;
    }
}