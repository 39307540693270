.quote_modal {
  padding: 35px 0;
  border: solid 2px $blue_komuna;
  border-radius: 15px;

  .modal-header {
    border: none;
    position: absolute;
    right: 10px;
    top: 0px;

    .close {
      font-size: 28px;
    }
  }

  .title,
  .value {
    text-align: center;
    font-size: 24px;
  }
  .title {
    font-weight: 400;
  }
  .value {
    font-weight: 600;
    &_small {
      font-weight: 600;
      text-align: center;
      font-size: 16px;
    }
  }

  input,
  .select {
    height: 40px;
    margin-bottom: 20px;
  }

  input,
  .select,
  textarea {
    font-size: 14px;
    &::placeholder {
      color: #212121;
    }
  }
  textarea {
    min-height: 100px;
  }
  .subtitle {
    margin: 30px 0;
    font-size: 42px;
    font-family: "La Belle Aurore", cursive;
  }
  .section_amount {
    font-size: 18px;
    text-align: center;
    margin: 0;
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
  }
  .form-container {
    margin: 0 0 50px 0;
  }
  .select {
    font-size: 14px;
    -webkit-appearance: none;

    background-image: url("/images/down-arrow.svg");
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 98%;
  }

  .quote-info-container,
  .quote-container {
    padding: 0 15px;
  }

  .quote-container {
    margin-top: 40px;
  }

  .dynamic {
    &-label {
      text-align: right;
    }
    &-value {
      font-weight: 600;
    }
    &-label,
    &-value {
      font-size: 16px;

      @include media-breakpoint-down(md) {
        text-align: center;
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }
  .info-message {
    font-size: 18px;
    text-align: center;
    margin-bottom: 35px;
  }
  .warning {
    text-align: center;
    font-size: 10px;
  }

  .investment-info {
    min-height: 180px;
    color: $blue_komuna;
    //background-color: $blue_komuna;
    background-image: url("/images/home/bg-beige.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    h4,
    h2,
    p {
      margin: 0;
    }

    h4,
    h2 {
      margin-bottom: 15px;
    }

    h4 {
      font-size: 18px;
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
    }
    p {
      font-size: 10px;
      opacity: 0.7;
    }
  }

  .btn.btn-custom {
    min-width: 150px;
    min-height: 39px;
    color: #ffffff;
    background-color: $blue_komuna;
    opacity: 1;
    font-size: 16px;
    border: 0;
    transition: 100ms ease-in-out all;
    position: relative;
    top: -15px;
    left: -15px;
    border-radius: 15px;

    &:active {
      background-color: darken($blue_komuna, 20%);
      color: #fefefe;
    }
  }
}
