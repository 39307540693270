.slick-comercial {

    img {
        padding: 0 15px;
    }

    &__buttons {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        
        img {
            width: 65px;
            cursor: pointer;
        }
    }

}